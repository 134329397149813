.specs .sidea .image {
    height: 100%;
}

.specs .sidea .image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(0.5);
}

.specs.container-fluid {
    padding: 0;
}


.specs .sidea .sideb {
    position: relative;
}

.specs .sidea {
    position: relative;
}



.specs .sidea .title {
    left: 4.5em !important;
    color: white !important;
}

.specs .sideb .datasheet {
    margin: auto;
    color: gray;
    border: solid 3px;
}

.specs .sideb.col-sm-6 {
    display: flex;
}

.specs .sideb .icon {
    font-size: 4em;
    text-align: center;
    color: #777
    
}

.specs .sidea.p-0.col-sm-6 {
    height: 100%;
}