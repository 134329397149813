.blocks {
  .subtitle {
    font-size: 1.6em;
  }
  .title::after {
    content: ".";
  }
  .block-item::after {
    background: linear-gradient(45deg, black, transparent) !important;
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
  }
  .block-item {
    height: calc(100% - 25em);
    padding: 1em;
    margin: 2em;
    background: aquamarine;

    video.video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
    .title {
      z-index: 7;
      position: absolute;
      bottom: 1em;
      font-size: 1.3em !important;
      color: greenyellow;
    }

    .icon {
      position: absolute;
      bottom: 1.6em;
      color: white;
      z-index: 6;
      font-size: 3em;
      line-height: 0em;
    }

    .title {
      font-size: 1em;
    }
    .image img {
      width: 100%;
    }

    .image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    position: relative;

    .image img {
      object-fit: cover;
      height: 100%;
    }
  }

  .title {
    font-size: 4em;
    font-weight: bolder;
  }

  .description {
    font-size: 1.4em;
    font-family: MadeTommyRegular;
  }
  .sidea {
    padding: 2em;
  }

  .sideb.col-sm-6 {
    padding: 3em;
  }

  .title::after {
    content: ".";
  }

  .block-item {
    margin-bottom: 1em !important;
  }

  .sidea,
  .sideb {
    height: 11em;
  }
}
