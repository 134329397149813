@media (max-width: 600px) {
    .theme1 {
        font-size: 8px !important;
        .header-img-overlay .text-content {
            margin-top: 16em !important;
        }
        
        .blocks.container-fluid {
            height: fit-content !important;
        }
        
        .blocks .sidea, .blocks .sideb {
            height: fit-content;
        }
        
        .blocks .block-item {
            min-width: 100% !important;
            min-height: 20em;
            padding: 1em;
            margin: 0;
        }
        
        .availability .description {
            left: 4em;
            margin-right: 2em;
        }
        
        .availability .title {
            top: 3em;
            left: 2em;
        }
        
        .location.container-fluid {
            height: fit-content !important;
        }
        
        .location .sidea {
            min-height: 21em;
        }
        
        .location .sideb {
            height: 45em !important;
        }
        
        .location .sideb .title {
            top: 4em !important;
        }
        
        .location .sideb .description {
            top: 9em;
        }
        
        .specs.container-fluid .sidea {
            height: 56em !important;
        }
        
        .specs.container-fluid {
            height: fit-content !important;
        }
        
        .specs.container-fluid .sideb {
            padding: 3em;
            height: 28em;
        }
        .specs.container-fluid .row {
            display: flex;
            flex-direction: column-reverse;
        }
        
        
        .contact-before.container-fluid .box {
            margin: auto !important;
            width: calc(100%  - 6em);
        }
    }
    
   

}