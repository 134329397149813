@media (max-width: 600px) {
  .theme1 {
    font-size: 5px !important;
  }
}

@import url("https://fonts.googleapis.com/css2?family=League+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;600&family=League+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arvo:wght@700&display=swap");
@import "../../fonts/made_tommy/font.scss";
$navcolor: rgb(255, 255, 7);

.theme1 {
  font-size: 10px;
  font-family: MadeTommyRegular;

  .visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 2s linear;
  }
  .hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 2s, opacity 2s linear;
  }
  .midsection {
    .text-container {
      position: absolute !important;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      color: white;
      display: flex;
      flex-direction: column;
      padding: 4em;
      text-align: center;
    }
    .description {
      font-size: 16px !important;
      margin: auto;
      display: block;
      margin-top: 0 !important;
      font-weight: 500;
      font-family: MadeTommyThin;
    }

    .title {
      margin: auto;
      display: block;
      margin-bottom: 0 !important;
      font-size: 24px !important;
    }
  }
  .allnavs {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .slider-img {
    position: relative;
    background: black;
  }
  .slider-img > div {
    position: absolute !important;
    top: 0;
  }
  .header-img {
    object-fit: cover;
  }
  .header-img-overlay {
    position: relative;
  }
  .header-img-overlay .title {
    margin-top: auto;
  }

  .header-img-overlay .text-content {
    width: 100%;
    display: block;
    margin: auto;
    padding: 1em;
    margin-bottom: 2em;
  }

  .header-img-overlay::after {
    content: "";
    position: absolute;
    background: linear-gradient(0, black, #00000000);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
  }

  .header-img-overlay .title {
    font-size: 4em;
    /*font-family: 'Arvo', serif !important;*/
  }

  .header-img-overlay .text {
    position: absolute;
    top: 0;
    color: white;
    right: 5em;
    font-weight: 700;
    display: flex;
    z-index: 1;
    text-align: right;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .header-img-overlay .text .title {
    width: 100%;
  }

  .header-img-overlay .subtitle {
    font-size: 1.5em;
  }
  video.w-100.h-100.header-img {
    width: calc(100% + 2em) !important;
    position: absolute;
    left: 0 !important; /* right: -1em; */
    max-width: none !important;
    background: black;
    filter: blur(20px);
  }

  .layout.flex-column {
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    overflow-x: clip;
    overflow-y: auto;
  }
  button.navbar-toggler:focus {
    outline: none;
    box-shadow: none;
  }
  .anouncement.navbar {
    padding: 1em;
    color: white;
    background: #222;
    font-weight: 500;
    font-size: 2em;
    .nav > div {
      width: 100%;
      margin: auto;
      display: block;
    }
    .container-fluid {
      display: flex;
    }
    .nav {
      display: block;
      margin: auto;
    }
  }
  .layout.flex-column {
    height: 100vh;
    width: 100%;
    position: fixed;
  }

  .announcement-container {
    display: flex !important;
    position: initial;
    button {
      border-radius: 2em;
      margin-right: 1em;
      font-size: 0.6em;
      background: brown;

      border: none;
      line-height: 2em;
    }
  }

  nav.site-nav.bg-light {
    background: $navcolor !important;
    color: black;
  }
  .site-nav img.brand-logo {
    height: 2em;
  }

  nav.site-nav {
    padding-top: 1em;
    padding-bottom: 1em;

    .navbar-text {
      color: black;
      font-size: inherit;
      font-weight: 600;
    }

    .navbar-brand {
      color: inherit;
    }

    .nav-item a {
      color: black;
      text-decoration: none;
    }

    .collapse ul {
      margin-left: auto;
    }

    .collapse ul a {
      font-size: 1.35em;
      margin: 0.3em;
      margin-top: auto;
      margin-bottom: auto;
      display: block;
      height: 100%;
      padding-top: 0.2em;
      font-weight: 600;
    }
  }
}

@import "./theme1/blocks.scss";
@import "./theme1/availability.scss";
@import "./theme1/specs.scss";
@import "./theme1/location.scss";
@import "./theme1/contact.scss";
@import "./theme1/mobile.scss";
@import "./theme1/password-reset.scss";
