
.location .sideb .image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.location .image {
    height: 100%;
}

.location .sideb.col-sm-6 {
    height: 100%;
}

.location.container-fluid {
    padding: 0;
}

.location.container-fluid {
    padding: 0;
}

.location .sideb {
    position: relative;
}

.location .sideb {
    color: white;
}

.location .sideb .image img {
    filter: brightness(0.5);
}

.location .sidea {
    display: flex;
}

.location .sidea .btn {
    margin: auto;
    display: block;
    color: gray;
    border: solid 3px;
}

.location .sidea .icon {
    font-size: 4em;
    text-align: center;
    color: #777
}

