

.contact .image {
    height: 100%;
    position: relative;
}

.contact .image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    padding: 2.5em;
    padding-top: 4em;
}

.contact .sideb.p-0.col-sm-6 {
    height: 100%;
}

.contact.container-fluid {
    padding: 0;
}

.contact .image::after {
    content: '';
    /* background: linear-gradient(45deg, #0f160e, transparent); */
    position: absolute;
    top: 4em;
    bottom: 4em;
    left: 4em;
    right: 4em;
    z-index: 3;
    padding: 4em;
}

.image {}

.contact .sidea.-\30 .col-sm-6 {
    height: 100%;
}

.contact .sidea.-\30 .col-sm-6 .form {
    padding: 3em;
    background: white;
    margin: 4em;
    color: #777;
    border: solid;
}

.contact .sidea.-\30 .col-sm-6 .form .form-label {
    font-size: 1.2em;
}


.contact form.form .form-control {
    border: solid 2px #ccc;
}

.contact .form button.btn.btn-secondary {
    width: 100%;
    border-radius: 0;
    /* background: #ccc; */
    /* color: #777; */
    /* border: solid 2px; */
}

.contact .title {
    font-size: 2em;
}

.contact .sideb.p-0.col-sm-6>div {
    /* height: 100%; */
    padding: 8em;
    text-align: center;
}

.contact .sideb.p-0.col-sm-6 {
    display: flex;
    padding: 4em;
    font-size: 1.4em;
}

.contact .sideb.p-0.col-sm-6 {}

.contact .sideb .icon {
    font-size: 2em;
    margin: .7em;
    display: flex;
    justify-content: space-around;
}

/**ver 2 */

.contact-2 .title, .contact-2 .form-title {
    font-size: 3em;
    font-weight: 700;
    color: #777
}

.contact-2 .sidea, .contact-2.container-fluid .sideb {
    padding: 3em !important;
}

.contact-2 .sidea .form, .contact-2.container-fluid .sideb>div {
    padding: 2em;
}

.contact-2 .icon {
    font-size: 3em;
    font-weight: 100;
    display: flex;
    justify-content: flex-start;
    margin: 1em;
    text-align: center;
    display: none;
}

.contact-2 .icon svg {
    margin-right: 1em;
}

.contact-2  .sideb.p-0.col-sm-6 {
    /* text-align: center; */
}

.contact-2 .sideb .text, .contact-2 .sidea label {
    font-size: 1.5em;
    margin-top: 1em;
    color: #777
    
}


.contact-2 .sidea button.btn.btn-secondary {background: #acd111 !important;border: none; font-family: sans-serif;font-weight: 700;width: 100%;}


.contact-before.container-fluid .image {
    height: 100%;
}

.contact-before.container-fluid .image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: blur(1px);
}

.contact-before.container-fluid {
    padding: 0;
}

.contact-before.container-fluid .image::after {
    content: '';
    position: absolute;
   // background: linear-gradient(0, black, transparent);
    z-index: 3;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.contact-before.container-fluid .image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.contact-before.container-fluid .image {
    height: 100%;
    background-color: rgba(255,255,0,.5);
}

// .contact-before.container-fluid .image img {
//     height: 100%;
//     width: 100%;
//     object-fit: cover;
//     filter: blur(1px);
// }

.contact-before.container-fluid {
    padding: 0;
}

.contact-before.container-fluid .image::after {
    content: '';
    position: absolute;
    // background: linear-gradient(0, black, transparent);
    z-index: 3;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.contact-before.container-fluid .image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.contact-before.container-fluid .box {
    height: auto;
    margin: auto;
    background: white;
    padding: 2em;
    font-size: 1.4em;
}

.content-before .sideb, .contact-before .sidea {
    display: flex;
}

.contact-before .sideb.p-0.col-sm-6 {
    display: flex;
}

.contact-before .sidea {
    display: flex;
}


.contact-before .sideb .box {
    margin-right: 1em !important;
}

.contact-before .sidea .box {
    margin-left: 1em;
}

.contact-before .box .title {
    font-size: 1.7em;
}

.contact-before .box .description {
    font-size: 1.2em;
}

.contact-before .box button {
    background: darkslategrey;
    margin-top: 1em;
    border: none;
    border-radius: 0;
}

.contact-before .box button.btn {
    margin-right: 1em !important;
}

.contact-before  .box button img {
    width: 1.5em;
}


.captcha {
    margin: auto;
    display: block;
    height: 4em;
    margin-right: 1em;
  }

  .contact-before .sideb .box {
    min-height: 16em !important;
    /* display: flex; */
    flex-direction: column;
    flex-wrap: nowrap;
    height: fit-content !important;
    margin: auto;
    position: relative;
    width: calc(100% - 6em);
}

.contact-before .sideb .box .title {
    margin-bottom: 0em;
}

.contact-before .sideb .box button.btn {
    position: absolute;
    bottom: 2em;
    
}



.contact-before .sidea .box {
    min-height: 16em !important;
    /* display: flex; */
    flex-direction: column;
    flex-wrap: nowrap;
    height: fit-content !important;
    margin: auto;
    position: relative;
    width: calc(100% - 6em);
}

.contact-before .sidea .box .title {
    margin-bottom: 0em;
}

.contact-before .sidea .box button.btn {
    position: absolute;
    bottom: 2em;
    width: calc(50% - 3em);
}

.contact-before .sidea .box button.btn:last-child {
    right: 1em;
}