@font-face {
    font-family: MadeTommyRegular;
    src: url("./regular.otf") format("opentype");
}

@font-face {
    font-family: MadeTommyBold;
    font-weight: bold;
    src: url("./bold.otf") format("opentype");
}

@font-face {
    font-family: MadeTommyThin;
    font-weight: thin;
    src: url("./thin.otf") format("opentype");
}

@font-face {
    font-family: MadeTommyMedium;
    font-weight: medium;
    src: url("./medium.otf") format("opentype");
}

@font-face {
    font-family: MadeTommyLight;
    font-weight: lighter;
    src: url("./light.otf") format("opentype");
}

@font-face {
    font-family: MadeTommyBolder;
    font-weight: bolder;
    src: url("./extra_bold.otf") format("opentype");
}
